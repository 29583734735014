import $ from 'jquery';
(<any>window).jQuery = $;
(<any>window).$ = $;

(<any>window).headerSwiper = null;
(<any>window).allMiniSwipers = [];

import './smooth-scroll';

const audioElement = document.getElementById('audioElement') as HTMLAudioElement;


$(document).ready(() => {
  $(".burgerbutton").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("opened");
    $("HEADER NAV").slideToggle(250);
  });

  $(".rapplayer").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("playing");


    if (this.classList.contains('playing')) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  });

  $("[data-cookie-consent]").on("click", function (e) {
    e.preventDefault();
  });

  // Slider initialisieren
  (<any>window).headerSwiper = new Swiper('.swiper-header', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 800,
    autoplay: {
      delay: 5000,
    },


    // If we need pagination
    pagination: {
      el: '#pagination-1',
      type: 'bullets',
      clickable: true
    },

    // Navigation arrows
    navigation: {
      nextEl: '#button-next-1',
      prevEl: '#button-prev-1',
    },
  });

});

$(window).on("scroll", () => {
  if ($(window).scrollTop() > 120) {
    $("BODY").addClass("smallnavi");
  } else {
    $("BODY").removeClass("smallnavi");
  }
});
