import $ from 'jquery';

(function () {

  const SMOOTH_SCROLL_ATTR = 'data-smooth-scroll';

  $(document).on('click', `[${SMOOTH_SCROLL_ATTR}]`, function (e) {
    const $currentTarget = $(e.currentTarget);
    const $href = $currentTarget.attr('href');

    var offsetTop = 0;

    if ($($href).length > 0)
      offsetTop = $($href).offset().top;

    if (!offsetTop && $href != "#top") return;

    offsetTop = offsetTop - $("HEADER").outerHeight() - 20;
    if( offsetTop < 0 ) offsetTop = 0;

    e.preventDefault();

    const duration = parseInt($currentTarget.attr(SMOOTH_SCROLL_ATTR)) || 1000;

    $(this).attr('data-stop', offsetTop);
    $(this).attr('data-start', $(window).scrollTop());
    $(window).scrollTop(offsetTop);
    $(this).css('perc', 0).animate({ perc: 1 }, {
      duration: duration, step: function (fx, p) {
        var newv = Number($(this).attr('data-start')) - fx * (Number($(this).attr('data-start')) - Number($(this).attr('data-stop')));
        $(window).scrollTop(newv);
      }
    });
  });

})();
